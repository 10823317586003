import React from "react"

export default () => {

  return (
    <>
      {/* <div className="section">
        <div className="container has-text-centered">
        <a name="votre-avis" id="votre-avis" />
          <p>
            Pour clôturer cette année, Pouet Pouet s'autorise <b>une petite enquête de satisfaction.</b><br />
            Vos réponses à ces quelques questions seront volontiers mise à profit pour la prochaine édition!
          </p>
          <img src='/enquete-noix.jpg' />
          <br />
          <a className="button is-primary has-text-weight-bold" href="https://us3.list-manage.com/survey?u=4d0bd64106691561bb29214c7&id=47f4e0c5d1&attribution=false">Laissez-moi votre avis par ici</a>
        </div>
      </div> */}
      <div class="section has-background-grey-light">
        <a name="cta" id="cta" />
        <div class="container">
          <div class="columns is-centered ">
            <div class="column is-10">
              <h2 class="title is-size-4-mobile">Chaque matin une illustration décalée pour égayer votre boîte mail déprimée</h2>
              <p class="subtitle">... à consommer avec votre café pour rester en bonne santé !</p>
              <form action="https://poney-illustrations.us3.list-manage.com/subscribe/post?u=4d0bd64106691561bb29214c7&amp;id=1674fab303" method="post">
                <div class="field has-addons">
                  <div class="control has-icons-left">
                    <input
                      class="input"
                      type="email"
                      name="EMAIL"
                      placeholder="bobonne@lescypres.com"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                  <div class="control">
                    <button class="button has-text-weight-bold is-dark" type="submit">
                      Je m'abonne
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}