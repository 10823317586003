import React, { useContext } from "react"
import "../styles/styles.scss"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Context } from "../utils/context"

export default ({ children, active, messageBody }) => {

    const { message: { showMessage, setShowMessage } } = useContext(Context)

    return (
        <>
            <div class="section has-background-white-ter">
                <div class="container has-text-centered">
                    <h1 class="title is-1"><Link to="/" className="has-text-dark">Pouet Pouet</Link></h1>
                    <p class="subtitle">Histoires illustrées sans complexes</p>
                </div>

            </div>
            {/* <div className="has-background-white-ter">
                <div className="container">
                    <div class="tabs is-centered is-boxed">
                        <ul>
                            <li className={!active ? "is-active" : ''}>
                                <Link to="/">Accueil</Link>
                            </li>
                            <li>
                                <a onClick={() => scrollTo("#cta")}>
                                    Recevoir
                                </a>
                            </li>
                            <li>
                                <a onClick={() => scrollTo("#votre-avis")}>
                                    Votre avis
                                </a>
                            </li>
                            <li className={active === 'coloriages' ? 'is-active' : ''}>
                                <Link to="/coloriages">Coloriages</Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div> */}
            {
                showMessage && messageBody &&
                <div className='container mt-5'>
                    <div className="columns is-centered">
                        <div className="column is-narrow">
                            <div className='message has-background-primary'>
                                <div className='message-body'>
                                    {messageBody}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            }
            {children}
            <footer class="footer">
                <div class="content has-text-centered">
                    <div class="columns is-centered">
                        <div class="column">
                            <h2 class="title is-size-4-mobile">
                                Recevoir une illu chaque matin
                            </h2>
                            <form action="https://poney-illustrations.us3.list-manage.com/subscribe/post?u=4d0bd64106691561bb29214c7&amp;id=1674fab303" method="post">

                                <div class="field has-addons" style={{ justifyContent: "center" }}>
                                    <div class="control has-icons-left">
                                        <input
                                            class="input"
                                            type="email"
                                            name="EMAIL"
                                            placeholder="bobonne@lescypres.com"
                                        />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <div class="control">
                                        <button class="button has-text-weight-bold is-dark" type="submit">
                                            Je m'abonne
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="columns is-centered my-5">
                        <div class="column">
                            <p class="subtitle">
                                Besoin de contacter le petit poney qui se cache derrière ces illus trululu chapeau pointu&nbsp;? <br />
                                <a href="mailto:pauline@poney-illustrations.be">pauline@poney-illustrations.be</a>
                            </p>
                        </div>
                    </div>
                    <p class="has-text-centered">
                        <strong>pouetpouet</strong> par
                        <a href="https://poney-illustrations.be"> Poney</a>. Tous droits
                        réservés.
                    </p>
                </div>
            </footer>
        </>
    )
}