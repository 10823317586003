import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import slugify from "../utils/slugify"

export default ({ post, noLink, ...otherProps }) => {
    return (
        <div class="columns is-centered">
            <div class="column is-12 py-6">
                {
                    noLink
                        ?
                        <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} alt={post.frontmatter.title} {...otherProps}/>
                        :
                        <Link to={"/illu/" + post.frontmatter.date + "/" + slugify(post.frontmatter.title)}>
                            <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} alt={post.frontmatter.title} {...otherProps} />
                        </Link>
                }
                {
                    post.frontmatter.youtubePlayButton &&
                    <div>
                        <a class="button" href={post.frontmatter.youtubePlayButton} target="_blank" rel="noreferrer noopener nofollow">
                            <span class="icon">
                                &#9654;
                                    </span>
                            <span>play</span>
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}